// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"


window.toggleFileBlock = function(id) {
  var elem = document.getElementById(id);
  var icon = document.querySelector('#toggler-' + id + ' > .icon')

  if (elem.style.display == 'none') {
    elem.style.display = 'block';
    icon.classList.remove('icono-caretRight');
    icon.classList.add('icono-caretDown');
  } else {
    elem.style.display = 'none';
    icon.classList.remove('icono-caretDown');
    icon.classList.add('icono-caretRight');
  }
}

window.toggleFacultyMenu = function() {
  var menu = document.querySelector('.menu')
  if (menu.style.display != 'block') {
    menu.style.display = 'block';
  } else {
    menu.style.display = 'none';
  }
}

window.toggleModeMenu = function() {
  var toggler = document.querySelector('.mode-menu-toggle')
  toggler.style.display = 'none';

  var menus = document.getElementsByClassName("mode-menu");
  for (var i = 0; i < menus.length; i++) {
    menus[i].style.display = 'block';
    
  }
}